@font-face {
	font-family: cabrito-flare;
	src: url("/static/font/cabrito/cabritoflarenormblackitalic.woff2") format("woff2");
	font-weight: normal;
}

@font-face {
	font-family: cabrito-flare-bold;
	src: url("/static/font/cabrito/cabritoflarenormblackitalic.woff2") format("woff2");
	font-weight: 1000;
}

@font-face {
	font-family: roboto-flex;
	src: url("/static/font/robotoFlex/RobotoFlex.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: roboto-flex-bold;
	src: url("/static/font/robotoFlex/RobotoFlex.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
}
