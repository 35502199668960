:root {
	--color-primary-100: #d9ece2;
	--color-primary-200: #b2d9c5;
	--color-primary-300: #ff989c;
	--color-primary-400: #ed1c24;
	--color-primary-500: #ed1c24;
	--color-primary-600: #981a15;
	--color-primary-700: #7e0f13;
	--color-primary-800: #401919;

	--color-secondary-100: #d9ece2;
	--color-secondary-200: #b2d9c5;
	--color-secondary-300: #87bfa1;
	--color-secondary-400: #00bb5b;
	--color-secondary-500: #108043;
	--color-secondary-600: #0a542c;
	--color-secondary-700: #083d20;
	--color-secondary-800: #06321a;

	--color-black: #0d0d0d;
	--color-gray-300: #f8f7f7;
	--color-gray-400: #ebebeb;
	--color-gray-500: #c2c0bf;
	--color-gray-600: #868686;
	--color-gray-700: #545352;

	// timer
	--color-background-timer: var(--color-secondary-500);
	--color-text-timer: white;

	// footer
	--color-background-footer: var(--color-black);
	--color-text-footer: white;

	// badge inclus
	--color-background-badge-inclus: var(--color-primary-600);
	--color-text-badge-inclus: white;

	// push notification
	--color-background-push-notification: var(--color-secondary-600);

	// active color
	--color-active: var(--color-secondary-500);

	// payment banner
	--payment-description-background-color: #efe9d6;
	--payment-description-instalments-background-color: #bba566;

	// choice
	--choice-background-color: var(--color-primary-800);

	// input-label
	--input-label-active-color: #707070;

	// picture shadow
	--picture-shadow-layer: rgba(64, 25, 25, 0.3);
}
