:root {
	--font-family: "roboto-flex", sans-serif;
	--font-family-bold: "roboto-flex-bold", sans-serif;
	// default font-sizes are defined in _common-typography.scss. Override the default font sizes here.
	--font-size-bold-xl5: 2.8rem;
	--font-size-bold-xl6: 3rem;

	--font-family-bold-xl3: "cabrito-flare-bold", sans-serif;
	--font-family-bold-xl4: "cabrito-flare-bold", sans-serif;
	--font-family-bold-xl5: "cabrito-flare-bold", sans-serif;
	--font-family-bold-xl6: "cabrito-flare-bold", sans-serif;
	--font-family-bold-xl7: "cabrito-flare-bold", sans-serif;
	--font-family-bold-xl8: "cabrito-flare-bold", sans-serif;
	--font-family-bold-xl9: "cabrito-flare-bold", sans-serif;
	--font-family-bold-xl10: "cabrito-flare-bold", sans-serif;
}

@media (min-width: $md) {
	:root {
		--font-size-bold-xl6: 3.8rem;
		--font-family-bold-xl3: "roboto-flex-bold", sans-serif;
		--font-family-bold-xl4: "cabrito-flare-bold", sans-serif;
		--font-family-bold-xl5: "cabrito-flare-bold", sans-serif;
		--font-family-bold-xl6: "cabrito-flare-bold", sans-serif;
		--font-family-bold-xl7: "cabrito-flare-bold", sans-serif;
		--font-family-bold-xl8: "cabrito-flare-bold", sans-serif;
		--font-family-bold-xl9: "cabrito-flare-bold", sans-serif;
		--font-family-bold-xl10: "cabrito-flare-bold", sans-serif;
	}
}
